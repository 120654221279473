<template>
  <div class="box">
    <van-sticky :offset-top="0">
      <van-search
        v-model="search.name"
        show-action
        shape="round"
        @focus="toSearch"
        placeholder="输入您要搜索的产品名，如：微业贷"
      >
        <template #action>
          <div @click="addressShow = true">
            {{ search.cityText }}<van-icon class="iconfont icon-xiala icont" />
          </div>
        </template>
      </van-search>
    </van-sticky>
    <van-popup
      v-model="addressShow"
      closeable
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="address-box">
        <div class="address-title">当前定位城市</div>
        <div class="location-box">
          <div class="location-name">
            {{ search.cityText }}<van-icon class="iconfont icon-Frame1 icont" />
          </div>
          <div class="location-button" @click="getCity">重新定位</div>
        </div>
        <van-cascader
          v-model="search.cityCodes"
          title="请选择所在地区"
          :closeable="false"
          :options="cityList"
          :field-names="fieldNames"
          @finish="cityOK"
          class="city-select"
        />
      </div>
    </van-popup>

    <div class="grid">
      <van-grid icon-size="34px" :border="false">
        <van-grid-item
          :icon="require('@/img/grid1.png')"
          text="免费测额"
          to="measurement"
        />
        <van-grid-item
          :icon="require('@/img/grid2.png')"
          text="智能客服"
          to="qa"
        />
        <van-grid-item
          :icon="require('@/img/grid3.png')"
          text="融资报告"
          @click="toReport"
        />
        <van-grid-item
          :icon="require('@/img/grid4.png')"
          text="VIP权益"
          to="vip"
        />
      </van-grid>
    </div>
    <div class="banner">
      <a :href="b.jumpUrl" v-for="b in bannerList" :key="b.bannerId">
        <img :src="b.bannerUrl"
      /></a>
    </div>
    <div class="hint">
      <img src="@/img/home/gonggao.png" class="hint-icon" />
      <van-swipe
        class="gonggao"
        vertical
        :autoplay="3000"
        :show-indicators="false"
        :touchable="false"
      >
        <van-swipe-item v-for="item in rollList" :key="item.companyId">
          <div class="hint-text">
            {{ item.companyName }} {{ item.institutionName }}放款
            <span style="color: #ef5446">{{ item.quota }}</span>
            元
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="product-box">
      <div class="bank-title">为您推荐 <span>企业主都在选的产品</span></div>
      <div
        class="product"
        v-for="item in recommendeList"
        :key="item.recommendId"
        @click="toInfo(item.recommendId, item.productId)"
      >
        <div class="product-top">
          <div style="display: flex; align-items: center">
            <van-icon
              :name="
                'https://img.weiqilile.com/bank/thumb/' +
                item.institutionCode +
                '.png'
              "
              size="16px"
              style="margin-right: 4px"
            />
            {{ item.name }}
          </div>
        </div>
        <van-row>
          <van-col span="8">
            <div class="price">{{ item.maxQuota }}万</div>
            <div class="product-name">最高额度</div>
          </van-col>
          <van-col span="8">
            <!-- <div class="rate">{{ item.lixi }}%</div>
            <div class="product-name">{{ item.lixiWenan }}</div> -->
            <div class="rate">{{ item.rate }}%</div>
            <div class="product-name">年化低至</div>
          </van-col>
          <van-col span="8">
            <van-button round type="info" color="#7A9DFF" class="product-button"
              >免费办理</van-button
            >
          </van-col>
        </van-row>
      </div>
    </div>

    <div class="product-box">
      <van-cell
        title="热门产品"
        value="查看更多"
        title-class="cell-title"
        value-class="cell-val"
        is-link
        to="productList"
      >
        <!-- <template #title>
          <span class="custom-title" style="color: #000000; font-size: 16px"
            >热门产品</span
          >
          <span style="color: #999999; font-size: 12px"
            >大额低息 通过率高 放款快</span
          >
        </template> -->
      </van-cell>
      <div
        class="product"
        v-for="item in hotProductList"
        :key="item.productId"
        @click="toInfo(item.recommendId)"
      >
        <div class="product-top">
          <div style="display: flex; align-items: center">
            <van-icon
              :name="
                'https://img.weiqilile.com/bank/thumb/' +
                item.institutionCode +
                '.png'
              "
              size="16px"
              style="margin-right: 4px"
            />
            {{ item.name }}
          </div>
        </div>
        <van-row>
          <van-col span="8">
            <div class="price">{{ item.maxQuota }}万</div>
            <div class="product-name">最高额度</div>
          </van-col>
          <van-col span="8">
            <!-- <div class="rate">{{ item.lixi }}%</div>
            <div class="product-name">{{ item.lixiWenan }}</div> -->
            <div class="rate">{{ item.rate }}%</div>
            <div class="product-name">年化低至</div>
          </van-col>
          <van-col span="8">
            <van-button round type="info" color="#7A9DFF" class="product-button"
              >免费办理</van-button
            >
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="bank-box">
      <div class="bank-title">推荐课程 <span>先让百万老板财务懂贷款</span></div>
      <img
        src="@/img/home/kecheng.png"
        alt=""
        class="kecheng"
        @click="toCourse"
      />
    </div>
    <div class="bank-box">
      <div class="bank-title">合作银行 <span>全网银行 尽在掌握</span></div>
      <van-grid icon-size="25px" :border="false" column-num="5">
        <van-grid-item
          :icon="'https://img.weiqilile.com/bank/thumb/' + b.code + '.png'"
          :text="b.name"
          v-for="b in bankList"
          :key="b.name"
        />
        <van-grid-item
          :icon="require('@/img/home/all.png')"
          text="更多银行"
          to="bank"
        />
      </van-grid>
    </div>
    <van-overlay
      :show="reportShow"
      @click="reportShow = false"
      :lock-scroll="true"
    >
      <div class="wrapper" @click.stop>
        <div style="position: relative">
          <img src="@/img/home/report.png" alt="" class="report-img" />
          <!-- <div class="report-button">了解详情</div> -->
          <van-button
            class="report-button"
            :to="userInfo.vip == 0 ? 'vipNo' : 'report'"
            >了解详情</van-button
          >
          <img
            src="@/img/home/close.png"
            alt=""
            class="close"
            @click="reportShow = false"
          />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import url from "@/service/url-config";
import AMap from "AMap";
export default {
  data() {
    return {
      userInfo: {},
      repayment: [], //产品还款方式
      bannerList: [],
      bankList: [],
      rollList: [],
      recommendeList: [],
      hotProductList: [],
      search: {
        name: "",
        cityCodes: "410100",
        cityText: "郑州市",
      },
      addressShow: false,
      cityList: [],
      fieldNames: {
        text: "name",
        value: "id",
        children: "children",
      },
      reportShow: false,
    };
  },
  created() {
    if (
      localStorage.getItem("wql_real_token") &&
      localStorage.getItem("wql_token") &&
      localStorage.getItem("wql_real_token") != "undefined"
    ) {
      this.getDict();
      this.getCity();
      this.$http.get(url.userInfo).then((res) => {
        this.userInfo = res.data;
      });
    } else {
      localStorage.clear();
      this.$http
        .post(url.login, {
          grant_type: "client_credentials",
          appVersion: "1.0.0",
          appId: "web",
        })
        .then((res) => {
          localStorage.setItem("wql_token", res.access_token);
          this.getDict();
          this.getCity();
        });
    }
  },
  methods: {
    init() {
      this.getBankList();
      this.getHomeRoll();
      this.getRecommendedProduct();
      this.gethotProduct();
      this.getBannerList();
      this.$http.get(url.address).then((res) => {
        this.cityList = res.data;
      });
    },
    //获取银行列表
    getBankList() {
      this.$http.get(url.bankList + "?size=9").then((res) => {
        this.bankList = res.data.content;
      });
    },
    //获取banner
    getBannerList() {
      this.$http.get(url.banner).then((res) => {
        this.bannerList = res.data;
      });
    },
    //获取走马灯提示
    getHomeRoll() {
      this.$http.get(url.homeRoll + "?size=100").then((res) => {
        this.rollList = res.data;
      });
    },
    //推荐产品列表
    getRecommendedProduct() {
      this.$http.get(url.recommendedProduct).then((res) => {
        // let zidian = {
        //   0: "等额本金",
        //   1: "等额本息",
        //   2: "先息后本",
        //   3: "等本等息",
        // };
        let zidian = {};
        this.repayment.forEach((v) => {
          zidian[v.value] = v.label;
        });
        res.data.forEach((v) => {
          // if (v.rateClerk && v.rateClerk.length > 0) {
          //   v.lixiWenan = v.rateClerk.split(/\s+/)[0];
          //   v.lixi = v.rateClerk.split(/\s+/)[1];
          // }
          if (v.repayment && v.repayment.length > 0) {
            v.fangshi = "";
            let arr = v.repayment.split(",");
            arr.forEach((a) => {
              v.fangshi += zidian[a] + " ";
            });
          }
        });
        this.recommendeList = res.data;
      });
    },
    //热门产品列表
    gethotProduct() {
      this.$http.get(url.hotProduct).then((res) => {
        let zidian = {};
        this.repayment.forEach((v) => {
          zidian[v.value] = v.label;
        });
        res.data.forEach((v) => {
          if (v.repayment && v.repayment.length > 0) {
            v.fangshi = "";
            let arr = v.repayment.split(",");
            arr.forEach((a) => {
              v.fangshi += zidian[a] + " ";
            });
          }
        });
        this.hotProductList = res.data;
      });
    },
    //字典
    async getDict() {
      await this.$http.get(url.dict).then((res) => {
        res.data.forEach((v) => {
          this[v.name] = v.dictDetails;
        });
      });
      this.init();
    },
    //获取定位信息
    getCity() {
      let this_ = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity((status, result) => {
          // console.log(result);
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            if (result.adcode.length > 0) {
              this_.search.cityText = result.city;
              this_.search.cityCodes = result.adcode;
            } else {
              this_.search.cityText = "郑州市";
              this_.search.cityCodes = 410100;
            }
          }
        });
      });
    },
    //搜索
    toSearch() {
      this.$router.push("search");
    },
    //地址选完
    cityOK({ selectedOptions }) {
      this.search.cityText = selectedOptions[1].name;
      this.addressShow = false;
    },
    //去融资报告
    toReport() {
      this.reportShow = true;
    },
    //去课程
    toCourse() {
      this.$router.push("course");
    },
    toInfo(recommendId, productId) {
      this.$router.push({
        path: "productInfo",
        query: {
          id: recommendId,
          productId: productId,
          type: "tuijian",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  min-height: 100vh;
  padding-bottom: 80px;
  .address-box {
    height: calc(100% - 40px);
    padding-top: 40px;
    .address-title {
      width: 345px;
      margin: 0 auto;
      margin-bottom: 10px;
      color: #999999;
      font-size: 14px;
    }
    .location-box {
      width: 345px;
      margin: 0 auto;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .location-name {
        color: #333333;
        font-size: 16px;
      }
      .location-button {
        font-size: 14px;
        color: #1377ff;
      }
    }
  }
  .address-box /deep/.van-cascader__options {
    height: 250px;
  }
  .banner {
    width: 100%;
    margin: 0 auto;
    overflow-x: scroll;
    display: flex;
    img {
      width: 200px;
      height: 100px;
      margin-left: 12px;
      border-radius: 10px;
    }
  }
  .hint {
    width: 355px;
    height: 40px;
    margin: 0 auto;
    border-radius: 10px;
    margin-top: 10px;
    background: #f5f5f5;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .hint-text {
    font-size: 12px;
    color: #666666;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .gonggao {
    flex: none;
    width: calc(355px - 50px);
    height: 40px;
    line-height: 40px;
  }
  .hint-icon {
    width: 59px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .grid {
    width: 355px;
    margin: 0 auto;
    border-radius: 10px;
  }
  .grid .van-grid-item__text text {
    font-size: 12px;
    color: #333333;
  }
  .tag-box {
    width: 355px;
    height: 130px;
    margin: 0 auto;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .tag {
    width: 80px;
    text-align: center;
  }
  .tag-icon {
    width: 34px;
    height: 34px;
  }
  .tag-title {
    font-size: 15px;
    color: #51608d;
  }
  .tag-hint {
    font-size: 10px;
    color: #8b94b3;
  }
  .product-box {
    width: 355px;
    background: #ffffff;
    margin: 0 auto;
    border-radius: 10px;
  }
  .cell-title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
  }
  .cell-val {
    font-size: 12px;
    color: #8f98b3;
  }
  .product {
    width: calc(350px - 16px);
    height: calc(105px - 15px);
    padding: 17px 10px;
    background: #f4f7ff;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .product-top {
    padding-bottom: 20px;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
  }
  .price {
    font-size: 22px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-bottom: 2px;
  }
  .rate {
    font-size: 20px;
    color: #f55c00;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 5px;
  }
  .mode {
    font-size: 14px;
    color: #4f5e89;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 10px;
    padding-top: 4px;
  }
  .product-name {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #888888;
  }
  .product-button {
    // width: 75px;
    height: 30px;
    color: 13px;
  }

  .bank-box {
    width: 355px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .bank-title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    padding: 10px;
    span {
      color: #999999;
      font-size: 11px;
      font-weight: normal;
    }
  }
  .bank-box /deep/ .van-grid-item__content {
    padding-left: 0;
    padding-right: 0;
  }
  .bank-box /deep/ .van-grid-item__text {
    font-size: 10px;
    color: #808aab;
    margin-top: 10px;
  }
  .kecheng {
    width: 350px;
    height: 125px;
    border-radius: 10px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .report-img {
    width: 263px;
    height: 334px;
  }
  .report-button {
    width: 100px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    color: #ff6103;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    bottom: 27px;
  }
  .close {
    width: 25px;
    height: 25px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -17.5px;
  }
}
</style>
